/*jshint forin:true, noarg:true, noempty:true, eqeqeq:true, bitwise:true, strict:true, undef:true, unused:true, curly:true, browser:true, indent:4, jquery:true */
/*jshint devel: true */
/* csAjaxform.js */
/*global initPhotoSwipeFromDOM: true*/

(function () {
    'use strict';
    (function ($) {
        $.fn.csAjaxFormRefresh = function (onlyElementData) {
            var ed = true;
            if (onlyElementData === false) {
                ed = false;
            }
            this.each(function (i, element) {
                doAjax($(element), undefined, ed);
            });
        };
    })(jQuery);

    function trackingToFormToStruct(form) {
        var fd = form.serializeArray();
        var rv = {};
        for (var d in fd) {
            if (d.name && d.value) {
                var o = rv;
                var names = d.name.replace(/\[[0-9]+\]$/, '').split('.');
                // FIXME read data attributes from input
                var sel1 = '[name="' + d.name + '"]';
                var sel2 = '[value="' + d.value + '"]';
                for (var i = 0; i < names.length; i++) {
                    var name = names[i];
                    if (!o[name]) {
                        if ((i + 1) === names.length) {
                            o[name] = [];
                        } else {
                            o[name] = {};
                        }
                        o = o[name];
                    } else {
                        o = o[name];
                    }
                }
                var sel = sel1 + sel2 + ',' + sel1 + ' ' + sel2;
                var data = form.find(sel).data();
                o.push({value: d.value, data: data});

            }
        }
        return rv;
    }

    function trackingAdditionalInfo(form) {
        return form.data();
    }
    jQuery.fn.outerHTML = function () {
        return jQuery('<div />').append(this.eq(0).clone()).html();
    };
    function doAjax(element, event, onlyElementData, action) {
        var form = element.closest('form');
        var urlHolder = element.closest('[data-target-url]');
        var ajaxCall = urlHolder.data('ajaxCall');
        var p = [];
        if (onlyElementData) {
            p.push({'name': element.attr('name'), 'value': element.val()});
        } else {
            p = form.serializeArray();
        }
        $.each(p,function(){
            var data = jQuery.trim(this.value);

            if(data === ''){
                this.value = '';
            }
            this.value = this.value.replace(/\s*$/,"");

        });

        if (action) {
            p.push({'name': action});
        }

        if (ajaxCall) {
            ajaxCall.abort();
        }

        if(urlHolder.find(".csPreloader").length !== 0){
            urlHolder.find(".csPreloader").addClass("active");
        }

        urlHolder.data('ajaxCall', $.ajax({
            url: urlHolder.data('target-url'),
            data: p,
            success: function (ajaxData, status, jqxhr) {
                if (form.data('trackingEvent')) {
                    var tEvent = jQuery.Event('cs:tracking');
                    tEvent.trackingEvent = form.data('trackingEvent');
                    tEvent.trackingData = {
                        form: trackingToFormToStruct(form),
                        additional: trackingAdditionalInfo(form)
                    };
                    $(':root').trigger(tEvent);
                }

                if (element.hasClass('js-may-reload-page')) {
                    if (jqxhr.getResponseHeader('X-Cs-Location') && jqxhr.getResponseHeader('X-Cs-Location') !== urlHolder.data('target-url')) {
                        window.location.href = jqxhr.getResponseHeader('X-Cs-Location');
                        return;
                    }
                }

                var data = $(ajaxData);
                if (event) {
                    $(':root').trigger(event);
                }
                //Rating PopUp Count issue Fix
                if(urlHolder.find('.js-logged-out').length > 0 && urlHolder.find('.js-anoymous-rating').length > 0){
                    var anoymousRatingInfo = urlHolder.find('.js-anoymous-rating').outerHTML();
                    var anonymousRatingCnt = data.find('[data-anonymous-rating-count]').attr('data-anonymous-rating-count');
                    if(anonymousRatingCnt !== ''  &&  parseInt(anonymousRatingCnt,10) > 1){
                        data.find('.js-anoymous-rating').html(anoymousRatingInfo);
                        if(data.find('.likeDislikeLoadMoreBtn').length > 0){
                            data.find('.js-anoymous-rating').addClass('hidden');
                        }

                    }else{
                        data.find('.list-group').append(anoymousRatingInfo);
                    }
                }

                urlHolder.replaceWith(data);
                initPhotoSwipeFromDOM('.mdb-lightbox');
                /* duplicate init!!! */
                data.csInit();
                if (urlHolder.hasClass('csWidget')) {
                    urlHolder.removeClass('csWidget');
                } else if (urlHolder.closest('.csWidget').length > 0) {
                    urlHolder.closest('.csWidget').addClass('is-loading');
                } else if (urlHolder.find('.js-loading').length > 0) {
                    urlHolder.removeClass('is-loading');
                } else {
                    urlHolder.find('.js-loading-indicator').show();
                }
                urlHolder.data('ajaxCall', undefined);
                element.closest('form.js-reload').each(function () {
                    var reloadForm = $(this);
                    var classList = reloadForm.attr('class').split(/\s+/);
                    var selector = '';
                    for (var ix = 0, len = classList.length; ix < len; ix++) {
                        var clazz = classList[ix];
                        var reloadClass = 'js-reload-';

                        if (clazz.startsWith(reloadClass)) {
                            clazz = clazz.substring(reloadClass.length);
                            if (clazz && clazz.length > 0) {
                                selector += '.js-' + clazz;
                            }
                        }
                    }
                    if(data.find('.alert-danger').length === 0){
                        $(selector).csAjaxFormRefresh();
                    }

                });

                if(urlHolder.find(".csPreloader").length !== 0){
                    urlHolder.find(".csPreloader").removeClass("active");
                }
            }
        }));

        if (urlHolder.hasClass('csWidget')) {
            urlHolder.addClass('is-loading');
        } else if (urlHolder.closest('.csWidget').length > 0) {
            urlHolder.closest('.csWidget').addClass('is-loading');
        } else if (urlHolder.find('.js-loading').length > 0) {
            urlHolder.addClass('is-loading');
        } else {
            urlHolder.find('.js-loading-indicator').show();
        }

    }

    function inAjaxForm(element, f) {
        var el = $(element);
        if (el.closest('.js-ajax-form').length > 0 || el.attr('disabled') === 'disabled') {
            f(el);
        }
    }

    function initForm(container) {

        function dropdownOpenHelper(event) {
            event.preventDefault();
            var wrp = $(event.target).closest('li').length ? $(event.target).closest('li') : $(event.target).closest('.row');
            wrp.addClass('dropdown-open');
            $(event.target).off('select2:open', dropdownOpenHelper);
            $(event.target).on('select2:close', dropdownCloseHelper);
        }

        function dropdownCloseHelper(event) {
            var wrp = $(event.target).closest('li').length ? $(event.target).closest('li') : $(event.target).closest('.row');
            $(event.target).off('select2:close', dropdownCloseHelper);
            $(event.target).on('select2:open', dropdownOpenHelper);
            wrp.removeClass('dropdown-open');
        }

        container.find('input[type="submit"], button[type="submit"]').click(function (e) {
            if(!e.isPropagationStopped()){
                var basketName = $(this).closest('form').find('.js-basketName')
                var basketNameVal = basketName.val();

                inAjaxForm(this, function (submit) {
                    e.preventDefault();

                    if(basketName.length > 0 && basketNameVal.indexOf("@") > -1){
                        var elB = $('.js-basketNameCheck');
                        elB.removeClass('hidden');
                        elB.removeAttr('style');
                        setTimeout(function () {
                            elB.fadeOut(1500);
                            elB.addClass('hidden');
                        }, 5000)

                        return false;
                    }else {
                        doAjax(submit, undefined, false, submit.attr('name'));
                    }

                });
            }
        });


        container.find('.js-autocheck').change(function () {
            inAjaxForm(this, function (el) {
                var stateChanged = false;
                var toState = el.prop('checked');
                el.closest('.js-autocheck-container').find('input:checkbox').each(function () {
                    if ($(this).prop('checked') !== toState) {
                        $(this).prop('checked', toState);
                        stateChanged = true;
                    }
                });
                if (stateChanged) {
                    doAjax(el, undefined, false, el.closest('form').find('input[type="submit"]').attr('name'));
                }
            });
        });

        container.find('.js-autosubmit-form').change(function () {


            if(!$(this).is(":checked")){
                var $autoCheck = $(this).closest('.js-autocheck-container').find('.js-autocheck').not(":hidden");
                if($autoCheck && $autoCheck.prop('checked')){
                    $autoCheck.prop('checked',false);
                }
            }
            inAjaxForm(this, function (el) {
                // if(el.is('select')){
                //   el.select2('close');
                // }
                el.closest('.csWidget-wrapper').find('.csPreloader');
                doAjax(el, undefined, false, el.closest('form').find('input[type="submit"]').attr('name'));
            });
        });


        container.find('.js-reload-form').change(function () {
            inAjaxForm(this, function (el) {
                doAjax(el, undefined, true);
            });
        });

    }

    $(document).ready(function () {
        $.csInit(function (e) {
            e.container.each(function () {
                initForm($(this));
            });

            if (!String.prototype.startsWith) {
                String.prototype.startsWith = function(searchString, position) {
                    position = position || 0;
                    return this.indexOf(searchString, position) === position;
                };
            }
        });
    });
})();
