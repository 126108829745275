/**
 * Created by asi on 3/15/18.
 */

/*jshint forin:true, noarg:true, noempty:true, eqeqeq:true, bitwise:true, strict:true, undef:true, unused:true, curly:true, browser:true,indent:4,  jquery:true */
/*jshint devel: true */

(function ($) {
    'use strict';
    var lastSelected;
    var deleteValue;

    function scrollbottom() {
        var chatEx = $('.csChat-room .csChat-1').length;
        if (chatEx > 0) {
            $(".csChat-1").addClass('scrollbar-light-grey');
            var msgDiv = $('.csChat-room .csChat-1')[0];
            msgDiv.scrollTop = msgDiv.scrollHeight;
        }
    }

    var privateMessageText = '';

    $.fn.csPrivateMessage = function () {
        var $container = $(this);
        setTimeout(function () {
            var $textarea = $('.js-private-message-text');
            if ($textarea.length > 0) {
                $textarea.scrollTop($textarea[0].scrollHeight);
            }

        }, 200);

        $container.find('.js-private-message-text').on('keyup', function () {
            privateMessageText = $(this).val();
        });

        $container.find('.js-privateMessageSubmit').off();
        $container.find('.js-privateMessageSubmit').on('click', function (event) {
            var $message = $(this).closest('.privateMessage');
            event.preventDefault();
            var $form = $(this).closest("form");
            $form.find('.error').hide();
            var $data = $.trim($form.find('textarea').val());
            if ($data === '') {
                $form.find('.error.textarea').first().show();
                return false;
            }
            if ($message.find(".csPreloader").length !== 0) {
                $message.find(".csPreloader:first").addClass("active");
            }
            var p = [];
            p = $form.serializeArray();
            var formData = new FormData();
            for (var i = 0; i < p.length; i++) {
                formData.append(p[i].name, p[i].value);
            }
            var xhr = new XMLHttpRequest();
            xhr.open('POST', $container.data().targetUrl + '&' + $form.find('.js-privateMessageSubmit').attr('name'));
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    privateMessageText = '';
                    if ($message.closest('.js-private-chat-box').length) {
                        var $holder = $message.closest('.js-private-chat-box');
                        $holder.find('.privateMessage').replaceWith($(xhr.responseText));
                        var newUser=$holder.closest('.private-message-top').find(".js-new-user");
                        if(newUser.length){
                            newUser.removeClass('js-new-user');
                        }
                        $holder.csInit();
                    } else {
                        $message.replaceWith($(xhr.responseText));
                    }
                    if ($message.find(".csPreloader").length !== 0) {
                        $message.find(".csPreloader").removeClass("active");
                        scrollbottom();
                    }
                }

            };
            xhr.send(formData);


        });
        $container.find('.js-private-msgDelete').off();
        $container.find('.js-private-msgDelete').on('click', function () {
            deleteValue = $(this);
            var $form = $container.find('.js-delete-message-form').find('form');
            var $li = $(this).closest('.js-message-div');
            $form.find('.js-message-asset-id').val($li.data('message-id'));
            $form.find('.js-message-type').val($li.data('message-type'));
        });


        $container.find('.js-private-message-delete').off();
        $container.find('.js-private-message-delete').on('click', function (e) {
            e.preventDefault();
            ajaxCallDelete($(this));

        });


    };

    function ajaxCallDelete($this) {
        var form = $this.closest('form');
        var urlHolder = $this.closest('[data-delete-msg-url]');
        var ajaxCall = urlHolder.data('ajaxCall');
        var p = form.serializeArray();
        $.each(p, function () {
            var data = jQuery.trim(this.value);
            if (data === '') {
                this.value = '';
            }
            this.value = this.value.replace(/\s*$/, "");

        });
        if (ajaxCall) {
            ajaxCall.abort();
        }
        $this.css('opacity', '0.2');
        urlHolder.data('ajaxCall', $.ajax({
                url: urlHolder.data('delete-msg-url'),
                data: p,
                success: function (ajaxData) {
                    if ($.trim(ajaxData) === "success") {
                        var currentLi = deleteValue.closest('li');
                        if (deleteValue.hasClass("js-private-msgDelete-all") && currentLi.hasClass('active')) {
                            if (currentLi.prev("li").length > 0) {
                                currentLi.prev("li").click();
                            } else if (currentLi.next("li").length > 0) {
                                currentLi.next("li").click();
                            } else {
                                currentLi.closest('.private-message-top').csAjaxFormRefresh();
                            }
                        }
                        currentLi.remove();
                        $this.closest('.js-delete-message-form').find('.close').click();
                        $this.css('opacity', '1.0');
                        deleteValue = '';
                    } else if (ajaxData === "error") {
                        console.log(ajaxData);
                    }
                }
            })
        );
    }

    function ajaxCall(privateMsg,lastSelected) {
        var idVal = privateMsg.find('.js-sender-id').val();
        if (idVal !== null && idVal !== undefined) {
            var url = privateMsg.closest('.private-message-top').data('url');
            $.ajax({
                url: url + "?notification.isPrivateMessgae=true&notification.parentAsset=" + idVal,
                success: function () {
                    //var result = $(ajaxData);]
                    $.fn.csNotificationRefresh();
                    scrollbottom();
                    setTimeout(function() {   //calls click event after a certain time
                        privateMsg.find("li").removeClass('csNewMsg');
                        if ($(lastSelected).find('.js-new-msg-icon').length) {
                            $(lastSelected).find('.js-new-msg-icon').remove();
                        }
                    }, 5000);
                }
            });
        }
    }

    function readMessage(){

    }

    $.fn.csGetPrivateMessage = function () {
        var $this = $(this);
        if($this.find('.js-new-user').length){
            $this.closest('.private-message-top').find('.js-private-message-text-area').focus();
        }
        if (lastSelected == null) {
            lastSelected = $this.find('.js-get-private-message')[0];
        }
        if ($('.message').length) {
            $('.message').off();
            $('.message').on('click', function () {
                setTimeout(function () {
                    scrollbottom();
                }, 200);
                ajaxCall($this.closest('.private-message-top').find('.privateMessage'),lastSelected);
            });
            if ($('.message').hasClass('active')) {
                ajaxCall($this.closest('.private-message-top').find('.privateMessage'),lastSelected);
            }
        } else {
            ajaxCall($this.closest('.private-message-top').find('.privateMessage'),lastSelected);
        }


        $this.find('.js-private-msgDelete-all').off();
        $this.find('.js-private-msgDelete-all').on('click', function (event) {
            event.stopPropagation();
            $(".ajaxPrivateChatDeleteAll").modal('show');
            deleteValue = $(this);
            var $form = $this.find('.js-delete-message-form').find('form');
            var $li = $(this).closest('.js-get-private-message');
            $form.find('.js-recipient-asset-id').val($li.data('id'));
        });
        $this.find('.js-private-message-delete').off();
        $this.find('.js-private-message-delete').on('click', function (e) {
            e.preventDefault();
            ajaxCallDelete($(this));
        });

        $this.find('.js-get-private-message').each(function () {
            $(this).off();
            $(this).on('click', function () {
                $(this).closest('.private-message-list').find('.js-get-private-message').removeClass('active');
                $(this).addClass('active');
                if ($(lastSelected).find('.js-new-msg-icon').length) {
                    $(lastSelected).find('.js-new-msg-icon').remove();
                }
                lastSelected = $(this);
                var privateMsg = $this.closest('.private-message-top').find('.privateMessage');

                if (privateMsg.length && $(this).data('id') !== privateMsg.find('.js-sender-id').val()) {
                    privateMsg.find('.js-loading-indicator').show();
                    var xhr = new XMLHttpRequest();
                    xhr.open('get', privateMsg.data().targetUrl + '&message.isAjax=true&message.parentAsset=' + $(this).data('id') + '&action.userPrivateData');
                    xhr.onreadystatechange = function () {
                        if (xhr.readyState === 4) {
                            privateMsg.replaceWith($(xhr.responseText));
                            $this.closest('.private-message-top').csInit();
                            scrollbottom();
                        }
                    };
                    xhr.send();
                }
            });
        });

        scrollbottom();
    };


}(jQuery));
$(document).ready(function () {
    'use strict';
    $.csInit(function (e) {
        if (e.container.hasClass('privateMessage')) {
            e.container.csPrivateMessage();
        } else if (e.container.find('.privateMessage').length) {
            e.container.find('.privateMessage').csPrivateMessage();
        }
        e.container.find('.private-message-list').csGetPrivateMessage();
        $('.js-new-user-message').off();
        $('.js-new-user-message').on('click', function () {
            $('.private-message-top').find('.js-new-user-id').val($(this).data('id'));
            $(this).closest('.ajaxModal').find('.close').click();
            $('.js-new-user-id').csAjaxFormRefresh();
        });
    });
    // setInterval(function(){
    //     $('.js-main-message-nav').csAjaxFormRefresh(false); // this will run after every 5 seconds
    // }, 5000);
});