

 $(document).ready(function(){
     'use strict';
     $.csInit(function(e){

         if(e.container.find('#carousel-wrapper').length > 0){
             if($("#carousel-wrapper").length > 0) {
                 $("#carousel-wrapper").CloudCarousel({
                     xPos: 350,
                     yPos: 60,
                     minScale: 0.2,
                     xRadius: 300,
                     yRadius: 10,
                     reflOpacity: 0.5,
                     reflHeight: 0,
                     reflGap: -10,
                     autoRotateDelay: 3000,
                     vertical: false,
                     autoRotate: 'left',
                     img: false,
                     mouseWheel:true
                 });
                 $('#carousel-wrapper').removeAttr('style');
                 $('#carousel-wrapper').css('position','absolute');
                 $('#carousel-wrapper').css('width','100%');
                 $('#carousel-wrapper').css('height','100%');
                 $('#carousel-wrapper').show();
                 $('#carousel-wrapper').parent().css('position','relative');
             }
         }

     });

 });




