/*jshint forin:true, noarg:true, noempty:true, eqeqeq:true, bitwise:true, strict:true, undef:true, unused:true, curly:true, browser:true, indent:2, jquery:true */
/*jshint devel: true */
/* csTopics.js */
$(document).ready(function() {
  'use strict';
  
  $('.csTopics .pager a').on('click', function() {
    var c = $(this).data('char');
    $('.csTopics .pager a').removeClass('active');
    $(this).addClass('active');
    if(c === 'all') {
      $('.csTopics .csTopics__sorting li').show();
    } else {
      $('.csTopics .csTopics__sorting li').hide();
      $('.csTopics .csTopics__sorting li.item_' + c).show();
    }
  });
});

