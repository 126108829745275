/*jshint forin:true, noarg:true, noempty:true, eqeqeq:true, bitwise:true, strict:true, undef:true, unused:true, curly:true, browser:true, indent:4, jquery:true */
/*jshint devel: true */
/*global StripeCheckout: true*/

$(document).ready(function () {
    'use strict';

    function stripeJs(e) {
        if (typeof StripeCheckout === "undefined") {
            return;
        }
        e.each(function () {
            var container = $(this);
            var form = container.find('form');
            var handler = StripeCheckout.configure({
                key: form.data('stripeKey'),
                image: form.data('stripeSiteImage'),
                locale: form.data('stripeLocale'),
                token: function (token) {
                    var i = container.find('input.js-stripe-token');
                    i.val(JSON.stringify(token));
                    i.change();
                }
            });
            form.find('button')[0].addEventListener('click', function (e) {
                // Open Checkout with further options:
                handler.open({
                    name: form.data('stripeSiteName'),
                    description: form.data('stripeSiteDescription'),
                    zipCode: form.data('stripeCheckZipCode'),
                    amount: form.data('basketValue') * 100,
                    currency: form.data('basketCurrency'),
                });
                e.preventDefault();
            });
            // Close Checkout on page navigation:
            var f = function () {
                handler.close();
                window.removeEventListener('popstate', f);
            };
            window.addEventListener('popstate', f);
        });
    }

    $.csInit(function (e) {
        if (e.container.hasClass('js-stripe-checkout')) {
            stripeJs(e.container);
        } else {
            stripeJs(e.container.find('.js-stripe-checkout'));
        }
    });
});
