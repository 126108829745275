/*jshint forin:true, noarg:true, noempty:true, eqeqeq:true, bitwise:true, strict:true, undef:true, unused:true, curly:true, browser:true, indent:4, jquery:true */
/*jshint devel: true */
/* csScripts.js */
$(document).ready(function() {
    'use strict';

    /**
     * <pre> content syntax highlighter
     * relies on jquery.snippet.min.js
     **/
    if(typeof $('element').snippet === 'function') {
        $('pre').each(function() {
            var codeType = $(this).attr('data-code-type') || 'xml';
            $(this).snippet(codeType, {
                style: 'ide-eclipse'
            });
        });
    }

    /**
     * teaser click handler on teaser text etc.
     */
    $(document).on('click', '*[data-click-handler]', function() {
        var a = document.createElement('a');
        a.href = $(this).attr('data-click-handler');
        a.target = $(this).attr('data-click-target');
        a.rel = $(this).attr('data-click-rel');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        // location.href = $(this).attr('data-click-handler');
    });


});
