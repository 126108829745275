/**
 * Created by asi on 4/23/18.
 */
/*jshint forin:true, noarg:true, noempty:true, eqeqeq:true, bitwise:true, strict:true, undef:true, unused:true, curly:true, browser:true,indent:4,  jquery:true */
/*jshint devel: true */
(function ($) {
    'use strict';


    $.fn.csNotification = function () {
        var $this = $(this);
        if ($this.data('result') !== undefined && $this.data('result') !== '') {
            $.ajax({
                url: $this.data('url') + "?notification.isNotification=true",
                success: function () {
                   // var result = $(ajaxData);
                }
            });
        }
    };
    $.fn.csNotificationRefresh = function () {
        $.ajax({                        // this will run after every 5 seconds
            url: "_/notification-refresh",
            success: function (ajaxData) {
                if (ajaxData !== null && ajaxData !== '') {
                    var obj = jQuery.parseJSON(ajaxData);
                    console.log(obj);

                    if ($('.js-main-message-nav').length) {
                        if ((obj.notificationCount !== 0 || obj.privateMessageCount !== 0)) {
                            $('.js-main-message-nav').addClass('active');
                        } else {
                            $('.js-main-message-nav').removeClass('active');
                        }
                    }
                    if ($('.js-user-notification-count').length) {
                        if (obj.notificationCount !== 0) {
                            $('.js-user-notification-count').text(obj.notificationCount);
                            $('.js-user-notification-count').addClass('active');
                        } else {
                            $('.js-user-notification-count').text(0);
                            $('.js-user-notification-count').removeClass('active');
                        }

                    }
                    if ($('.js-user-message-count').length) {
                        if (obj.privateMessageCount !== 0) {
                            $('.js-user-message-count').text(obj.privateMessageCount);
                            $('.js-user-message-count').addClass('active');
                        } else {
                            $('.js-user-message-count').text(0);
                            $('.js-user-message-count').removeClass('active');

                        }

                    }
                }
            }
        });
    };


}(jQuery));
$(document).ready(function () {
    'use strict';
    $.csInit(function (e) {
        e.container.find('.notification-mark-read').csNotification();
    });
    if ($('.js-main-message-nav').length) {
        var $this = $('.js-main-message-nav');
        if ($this.data('notification-refresh') !== undefined && $this.data('notification-refresh') !== false) {
            var seconds = $this.data('notification-refresh-time') !== undefined ? $this.data('notification-refresh-time') : 5;
            setInterval(function () {
                $.fn.csNotificationRefresh();
            }, seconds * 1000);
        }

    }
});
