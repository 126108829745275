/*jshint forin:true, noarg:true, noempty:true, eqeqeq:true, bitwise:true, strict:true, undef:true, unused:true, curly:true, browser:true, indent:4, jquery:true */
/*jshint devel: true */

(function ($) {
    'use strict';
    function showPopUp(e, isEvent) {
        var $content, offsetLeft, offsetTop;
        var $element;

        if (isEvent) {
            $element = $(e.target);
        } else {
            $element = $(e);
        }


        if ($element.find('.js-bookmarkContent').length) {
            $content = $element.find('.js-bookmarkContent');
            offsetLeft = $element.closest('.csTeaser__bookmark').offset().left;
            offsetTop = $element.closest('.csTeaser__bookmark').offset().top;
        } else if ($element.find('.basketContent').length) {
            $content = $element.find('.basketContent');
            offsetLeft = $element.closest('.csTeaser__basket').offset().left;
            offsetTop = $element.closest('.csTeaser__basket').offset().top;
        } else if ($element.find('.downloadContent').length) {
            $content = $element.find('.downloadContent');
            offsetLeft = $element.closest('.csTeaser__download').offset().left;
            offsetTop = $element.closest('.csTeaser__download').offset().top;
        } else if ($element.find('.permissionContent').length) {
            $content = $element.find('.permissionContent');
            offsetLeft = $element.closest('.csTeaser__permission').offset().left;
            offsetTop = $element.closest('.csTeaser__permission').offset().top;
        }

        if ($content) {
            if ($(window).outerWidth() / 2 > offsetLeft) {
                $content.css('right', 'auto');
                $content.css('left', 'auto');
            } else {
                $content.css('right', '0');
                $content.css('left', 'auto');
            }

            //var offsetTop = $(this).closest('.csTeaser__bookmark').offset().top;
            var docViewTop = $(window).scrollTop();
            var docViewBottom = docViewTop + $(window).height();

            // $content.closest('.dropdown').removeClass('dropup');
            if ((docViewBottom - offsetTop) <= $(window).height() / 2) {
                $content.closest('.dropdown').addClass('dropdown-above');
            //     $content.closest('.dropdown').addClass('dropup');
            }
            if ($(window).width() <= 600) {
                $content.css('left', 2 * parseInt($('.main-wrapper').find('.container').first().css('padding-left'), 10) - offsetLeft);

            }
        }


    }

    $.fn.csAdjustDropDown = function (e, isEvent) {
        showPopUp(e, isEvent);
    };


}(jQuery));
