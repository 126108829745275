/**
 * Created by ink on 01/09/17.
 */

$(document).ready(function () {
    'use strict';
    $.csInit(function (e) {
        $('.mdb-lightbox').off('click');
        initPhotoSwipeFromDOM('.mdb-lightbox');
    });
});
