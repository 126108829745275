/*jshint forin:true, noarg:true, noempty:true, eqeqeq:true, bitwise:true, undef:true, unused:true, curly:true, browser:true, indent:4, jquery:true */
/*jshint devel: true */
/*global flowplayer: true */
/* ####  csVideo.js ### 
 *  flowplayer control handling
 */
$(document).ready(function () {
    'use strict';

    var conf = {
        key: '$969805118059127,$107498620017746',  // domain specific flowplayer licence key
        native_fullscreen: true,
    };

    $.csInit(function (e) {
        init(e.container);
    });


    function init(container) {
        container.find('.videoplayer').each(function (i, el) {

            var video = $(el).find('video'),
                sources = [],
                qualities = [],
                defaultQuality;

            $(el).find('.qualbuttons li').each(function (index, item) {
                sources.push({
                    src: $(item).attr('data-url'),
                    type: 'video/mp4', // type attribute currently missing on elements
                    quality: $(item).attr('data-resolution')
                });
                if ($(item).hasClass('active')) {
                    defaultQuality = $(item).attr('data-resolution');
                }
                qualities.push($(item).attr('data-resolution'));
            });

            var fl = flowplayer(el, {
                embed: false,
                splash: video.attr('poster') || 0,
                ratio: 0.56206,
                key: conf.key,
                share: false,
                native_fullscreen: conf.native_fullscreen,
                zeropreload: true,
                swf: '/img/flowplayer.swf',
                brand: {
                    text: ''
                },
                clip: {
                    qualities: qualities,
                    defaultQuality: defaultQuality,
                    sources: sources
                }
            });


            fl.on("quality", function (e, api, option) {

                var currentTime = api.finished ? 0 : api.video.time;
                var selection = getObjects(sources, "quality", option);

                if (selection.length) {

                    api.load({
                        sources: [
                            {type: 'video/mp4', src: selection[0].src}
                        ],
                        quality: option,
                        qualities: qualities

                    }, function () {
                        if (currentTime && !api.live) {
                            api.seek(currentTime, function () {
                                api.resume();
                            });
                        }
                    });
                }
            });

        });

        function getObjects(obj, key, val) {
            var objects = [];
            for (var i in obj) {
                if (obj.hasOwnProperty(i)) {
                    if (typeof obj[i] === 'object') {
                        objects = objects.concat(getObjects(obj[i], key, val));
                    } else if (i === key && obj[key] === val) {
                        objects.push(obj);
                    }
                }
            }
            return objects;
        }
    }
});
