/*jshint forin:true, noarg:true, noempty:true, eqeqeq:true, bitwise:true, strict:true, undef:true, unused:true, curly:true, browser:true, indent:4, jquery:true */
/*jshint devel: true */
/* csDropdown.js */
$(document).ready(function () {
    'use strict';


    /* Mobile Navigation */
    /**
     * Dropdown Toggle for language-switch inside Footer
     **/
    var langSwitchTrigger = '.csFooter__bottom .csFooter-language-switcher';
    var $langContainer = $(langSwitchTrigger);
    var $langTarget = '';

    $(document).on('click', langSwitchTrigger, function (e) {
        e.preventDefault();
        e.stopPropagation();
        $langTarget = $(e.target);

        if ($langContainer.hasClass('isOpen')) {

            if ($langTarget.closest('.csFooter-language-switcher').length > 0 && typeof $langTarget.attr('href') === "string") {
                window.location = $langTarget.attr('href');
            } else {
                $langContainer.removeClass("isOpen");
            }

        } else {
            $langContainer.addClass("isOpen");
            $(document).one('click', function () {
                $langContainer.removeClass('isOpen');
            });
        }
    });


    /**
     * Dropdown Toggle for Login
     **/
    var loginTriggerCls = '.csHeader .csLogin';
    var $loginContainer = $(loginTriggerCls);
    var $target = '';

    $(document).on('click', loginTriggerCls, function (e) {
        e.preventDefault();
        e.stopPropagation();
        $target = $(e.target);
        if ($loginContainer.hasClass('isOpen')) {

            if ($target.closest('.csLogin').length > 0 && typeof $target.attr('href') === "string") {
                window.location = $target.attr('href');
            } else {
                $loginContainer.removeClass("isOpen");
            }

        } else {
            $loginContainer.addClass("isOpen");
            $(document).one('click', function () {
                $loginContainer.removeClass('isOpen');
            });
        }
    });

});
