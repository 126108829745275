$(document).ready(function() {
  'use strict';

  function csMemoryGame(element){

    var $game = $(element);

    var BoxOpened = '';
    var ImgOpened = '';
    var Counter = 0;
    var ImgFound = 0;

    var $srcHolder = $game.find('.csMemory__cards');
    var $Source = $srcHolder.find('.csMemory__src');
    var $counter = $game.find('.csMemory__counter');
    var cover = $game.find('.csMemory__cards .csMemory__cover').first().attr('src');
    var cardClass = $('#card00').attr('class');

    var srcs = [];

    $.each($Source, function(i, el){
      srcs.push($(el).attr('src'));
    });
    
    $.each(srcs, function(i, val) {
      $srcHolder.append('<div id=card' + 1 + i + '><div class="inner"><img class="csMemory__cover " src=' + cover + ' /> <img class="csMemory__src" src=' + val + ' /></div></div>');
    });
    $("[id^='card']").each(function(){
      if($(this).attr('class') === undefined){
        $(this).attr('class',cardClass);
      }
    });

    function randomFunction(MaxValue, MinValue) {
      return Math.round(Math.random() * (MaxValue - MinValue) + MinValue);
    }

    function shuffleImages() {
      var ImgAll = $srcHolder.find('img.csMemory__src');
      var ImgThis = $srcHolder.find('div').first();
      var ImgArr = [];

      for (var i = 0; i < ImgAll.length; i++) {
        ImgArr[i] = ImgThis.find('img.csMemory__src').attr('src');
        ImgThis = ImgThis.next();
      }

      ImgThis = $srcHolder.find('div').first();

      for (var z = 0; z < ImgAll.length; z++) {
        var randomNumber = randomFunction(0, ImgArr.length - 1);

        $('#' + ImgThis.attr('id') + ' img.csMemory__src').attr('src', ImgArr[randomNumber]);
        ImgArr.splice(randomNumber, 1);
        ImgThis = ImgThis.next();
      }
    }
    
    shuffleImages();


    function resetGame() {
      shuffleImages();
      $srcHolder.find('div').each(function(i, el){
        $(el).removeAttr('style');
        $(el).find('img.csMemory__src').removeAttr('style');
      });
      Counter = 0;
      $game.find('.msg-success').hide();
      $counter.html('' + Counter);
      $game.find('csMemory__success').remove();
      $game.find('csMemory__counter').html('' + Counter);
      BoxOpened = '';
      ImgOpened = '';
      ImgFound = 0;
      return false;
    }

    $game.find('.csMemory__reset').click(resetGame);

    var openCard = function () {
      var id = $(this).attr('id');

      if ($('#' + id + ' img.csMemory__src').is(':hidden')) {
        $srcHolder.find('div').unbind('click', openCard);

        $('#' + id + ' img.csMemory__src').fadeIn('fast');

        if (ImgOpened === '') {
          BoxOpened = id;
          ImgOpened = $('#' + id + ' img.csMemory__src');
          setTimeout(function() {
            $srcHolder.find('div').bind('click', openCard);
          }, 300);
        } else {
          var CurrentOpened = $('#' + id + ' img.csMemory__src').attr('src');
          if (ImgOpened.attr('src') !== CurrentOpened) {
            setTimeout(function() {
              $('#' + id + ' img.csMemory__src').fadeOut('fast');
              $('#' + BoxOpened + ' img.csMemory__src').fadeOut('fast');
              BoxOpened = '';
              ImgOpened = '';
            }, 400);
          } else {
            $('#' + id ).css('visibility', 'hidden');
            $('#' + BoxOpened ).css('visibility', 'hidden');
            ImgFound++;
            BoxOpened = '';
            ImgOpened = '';
          }
          setTimeout(function() {
            $srcHolder.find('div').bind('click', openCard);
          }, 400);
        }
        Counter++;
        $counter.html('' + Counter);

        if (ImgFound === srcs.length) {
          $game.find('.msg-success').show();
        }
      }
    };
    $srcHolder.find('div').click(openCard);
  }


  $(function() {
    $('.csMemory').each(function(){
      csMemoryGame(this);
    });
  });
});
