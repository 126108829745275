/*jshint forin:true, noarg:true, noempty:true, eqeqeq:true, bitwise:true, undef:true, unused:true, curly:true, browser:true, indent:2, jquery:true */
/*jshint devel: true */
/*global soundManager:true */
/* ####  csAudio.js ### 
*/
$(document).ready(function() {
  'use strict';
  // soundManager.setup({
  //   preferFlash: false,
  //   //, url: "swf/"
  //   onready: function () {
  //     $('.audioplayer').each(function(i, el) {
  //       soundManager.createSound({
  //         id: "sound"+i, 
  //         url: $(el).find('.qualbuttons > li').data('url'),
  //         onfinish : function() {
  //           $(el).find('.playPauseButton').removeClass('active');
  //         }
  //       });
  //     });
    

  //   }
  // }).beginDelayedInit();
  
  // $('.audioplayer').each(function(i, el) {

  //   $(el).find('.playPauseButton').on('click',function(){
  //     if($(this).hasClass('active')){
  //       soundManager.pause("sound"+i);
  //     }
  //     else{
  //       soundManager.play("sound"+i,{volume:50});  
  //     }
  //     $(this).toggleClass('active');
  //   });     
  // });

});
