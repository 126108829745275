/*jshint forin:true, noarg:true, noempty:true, eqeqeq:true, bitwise:true, strict:true, undef:true, unused:true, curly:true, browser:true, indent:2, jquery:true */
/*jshint devel: true */
/* csCookie.js */
$(document).ready(function() {
  'use strict';

  $('#disclaimerCookie').each(function() {
    var $container = $(this);
    var $hideButton = $('#disclaimerCookieHide');
    if(typeof $.cookie('disclaimerCookie') !== 'undefined') {
      return;
    }
    $container.show();
    $('body').addClass('cs_cookies');
    $hideButton.on('click' , function(e) {
      e.preventDefault();
      $.cookie('disclaimerCookie', '1', { expires: 365, path: '/' });
      $('body').removeClass('cs_cookies');
      $container.hide();
    });
  });
});
