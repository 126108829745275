/**
 * Created by ink on 24/01/17.
 */


(function ($) {
    'use strict';

    $.fn.csPollResult = function () {

        return this.each(function() {
            var $this = $(this);

            var $optionContainer = $(this).find('.js-poll-options');
            var $options = $optionContainer.find('.js-poll-option');
            var $availableThumbs = $(this).find('.js-thumb');
            //Hide all Thumbs in starting
            $availableThumbs.hide();

            if ($optionContainer.length) {
                $options.click(function () {
                    var $optionThumb = $this.find('.js-thumb');
                    $optionThumb.hide();

                    var targetId = $(this).data('target-id');
                    if(targetId === undefined){
                        targetId = $(this).find('[data-target-id]').data('target-id');
                    }
                    $optionThumb = $this.find('.js-poll-option-' + targetId).find('.js-thumb');
                    $optionThumb.show();

                });
            }
        });




    };
}(jQuery));

$(document).ready(function () {
    'use strict';
    $.csInit(function (e) {
        e.container.find('.js-poll-form').csPollResult();

    });

});
