/*jshint forin:true, noarg:true, noempty:true, eqeqeq:true, bitwise:true, strict:true, undef:true, unused:true, curly:true, browser:true, indent:4, jquery:true */
/*jshint devel: true */
/* csInit.js */

'use strict';

$(document).ready(function() {
    $.csInit(function(e){
        e.container.find('.js-fade-out').each(function(){
            var el=$(this);
            setTimeout(function() {
                el.fadeOut();
            }, 5000 );
        });
    });
});
