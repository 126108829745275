/*jshint forin:true, noarg:true, noempty:true, eqeqeq:true, bitwise:true, strict:true, undef:true, unused:true, curly:true, browser:true, indent:4, jquery:true */
/*jshint devel: true */
/* global History: true */
/* csSearch.js */
(function ($) {
    'use strict';
    $.fn.setCursorToTextEnd = function () {
        var $initialVal = this.val();
        this.val($initialVal);
    };
})(jQuery);

$(document).ready(function () {
    'use strict';


    function initSearch(container) {
        container.find('input.js-filter').each(function () {
            var val = {};
            if ($(this).val()) {
                try {
                    val = JSON.parse($(this).val());
                } catch (e) {
                }
            }
            $(this).data('value', val);

        });

        container.closest('.csSnippet').find('input.js-sorting').each(function () {
            var val = [{
                'direction': 'desc'
            }];
            if ($(this).val()) {
                try {
                    val = JSON.parse($(this).val());
                } catch (e) {
                }
            }
            $(this).data('value', val);
        });

        container.find('select.js-filter-data').each(function () {
            var select = $(this);
            var input = select.closest('form').find('.js-filter');

            function update() {
                // for Media Search Predefined ...
                var fsetIn = select.closest('form').find('.js-ajax-fset');
                if (fsetIn !== undefined) {
                    var nofilter = true;
                    $.each(input.data('value'), function (key, value) {
                        if (nofilter) {
                            nofilter = value.length < 2;
                            if (nofilter && value.length === 1) {
                                nofilter = value[0] === '';
                            }
                        }
                    });
                    fsetIn.val(!nofilter);
                }
                input.val(JSON.stringify(input.data('value')));
                input.change();
            }

            select.on('change', function () {
                input.data('value')[select.data('feature')] = [];
                select.find('option:selected').each(function (i, selected) {
                    input.data('value')[select.data('feature')].push($(selected).val());
                });
                update();
            });

        });
        container.find('select.js-sorting-data').each(function () {
            var select = $(this);
            var input = select.closest('form').find('.js-sorting');

            {
                var feature = input.data('value')[0].feature;
                if (feature) {
                    select.find("option[value='" + feature + "']").attr('selected', 'selected');
                }
            }
            select.on('change', function () {
                var inputVal = input.data('value');
                if (select.val() !== '') {
                    inputVal[0].feature = select.val();
                    input.data('value', inputVal);
                } else {
                    delete inputVal[0].feature;
                }
                input.val(JSON.stringify(input.data('value')));
                input.change();

            });

        });
        container.find('button.js-button-down, button.js-button-up').each(function () {
            var button = $(this);
            var input = button.closest('.js-filter-snippet').find('.js-sorting');
            button.removeClass('active');
            if (button.hasClass('js-button-down') && input.data('value') && input.data('value')[0].direction === 'desc') {
                button.addClass('active');
            } else if (button.hasClass('js-button-up') && input.data('value') && input.data('value')[0].direction === 'asc') {
                button.addClass('active');
            }
            button.click(function (e) {
                e.preventDefault();
                if (button.hasClass('js-button-down')) {
                    input.data('value')[0].direction = 'desc';
                } else {
                    input.data('value')[0].direction = 'asc';
                }
                input.val(JSON.stringify(input.data('value')));
                input.change();
            });
        });

        container.find('button[type=reset]').click(function (e) {
            e.preventDefault();
            container.find('.js-ajax-value').not('.js-sorting').val('');
            container.closest('form').find('input[type=search]').val('');
            container.find('label').removeClass('active');
            if(window.cs.Dropdown){
                /* cancel keep open */
                delete window.cs.Dropdown;
            }
            doAjax($(this));
        });

        var keyupTimeout;
        container.find('input[type=search].js-text-search').off();
        container.find('input[type=search].js-text-search').bind('input search', function () {
            var el = $(this);
            if (el.val() && el.val().length > 3) {
                if (el.data('value') !== el.val()) {
                    container.find('.js-ajax-page').val('1');
                    if (keyupTimeout) {
                        clearTimeout(keyupTimeout);
                    }
                    keyupTimeout = setTimeout(function () {
                        doAjax(el);
                    }, 300);
                }
            } else if (el.val().length === 0) {
                container.find('.js-text-search').val('');
                container.closest('form').find('input[type=search]').val('');
                doAjax($(this));
            }
            el.data('value', el.val());
        });

        container.find('input[type="search"]').on('search', function (e) {
            e.preventDefault();
            var el = $(this);
            if (!$(this).val()) {
                doAjax(el);
            }
        });

        container.find('input[type=hidden].js-ajax-trigger-change').on('change', function () {
            var el = $(this);
            if (el.hasClass('js-filter')) {
                container.closest('.csSnippet').find('.js-ajax-page').val(1);
            }
            doAjax(el);
        });
        var pageInput = container.closest('.csSnippet').find('.js-ajax-page');

        container.find('.pagination a[data-page]').on('click', function (e) {
            e.preventDefault();
            pageInput.val($(this).data('page'));
            pageInput.change();

            if(window.cs.Dropdown){
                /* cancel keep open */
                delete window.cs.Dropdown;
            }

            // scroll top
            $('body').animate({
                scrollTop: 0
            }, 'fast');
        });

        /* media search only */
        container.find('button.js-button-list, button.js-button-tile').each(function () {
            var button = $(this);
            var input = button.closest('.js-filter-snippet').find('.js-ajax-variant');
            if (input.val() !== undefined) {
                button.removeClass('active');
                if (button.hasClass('js-button-list') && input.val() === 'list') {
                    button.addClass('active');
                    button.closest('.js-filter-snippet').find('.csGrid--flex').addClass('csGrid--list');
                } else if (button.hasClass('js-button-tile') && input.val() === 'tile') {
                    button.addClass('active');
                    button.closest('.js-filter-snippet').find('.csGrid--flex').removeClass('csGrid--list');
                }
            }
            button.click(function (e) {
                e.preventDefault();
                if (button.hasClass('js-button-list')) {
                    input.val('list');
                    button.addClass('active');
                    button.closest('.js-filter-snippet').find('button.js-button-tile').removeClass('active');
                    button.closest('.js-filter-snippet').find('.csGrid--flex').addClass('csGrid--list');
                } else {
                    input.val('tile');
                    button.addClass('active');
                    button.closest('.js-filter-snippet').find('.csGrid--flex').removeClass('csGrid--list');
                    button.closest('.js-filter-snippet').find('button.js-button-list').removeClass('active');
                }
                //input.change();
            });
        });

        container.find('select.js-predef-data').each(function () {
            var select = $(this);

            var input = select.closest('form').find('.js-ajax-predefined');

            select.on('change', function () {
                //   var inputVal = input.data('value');
                if (select.val() !== '') {
                    input.val(select.val());
                    container.closest('.csSnippet').find('.js-ajax-page').val(1);
                } else {
                    input.val(undefined);
                }
                input.change();

            });
        });
    }

    function doAjax(element) {
        var form = element.closest('form');
        var ajaxCall = form.data('ajaxCall');
        var textValue=form.find('.js-text-search').val();
        var p;
        if(textValue !==undefined && textValue!=='' && textValue.length>3){
            p = form.serializeArray();
        }else{
            p = form.find(":not(.js-text-search)").serialize();
        }


        var snippet = form.closest('.csSnippet');


        if (ajaxCall) {
            ajaxCall.abort();
        }
        form.data('ajaxCall', $.ajax({
            url: form.data('target-url'),
            data: p,
            success: function (data) {
                //snippet.find('.js-filter-replace select').each(function(){
                //    var el=$(this);
                //    el.select2('destroy');
                //});
                if(snippet.find('.js-filter-replace').length >0 || snippet.find('.js-result-replace').length > 0){
                    snippet.find('.js-result-replace').replaceWith($(data).find('.js-result-replace'));
                    snippet.find('.js-filter-replace').replaceWith($(data).find('.js-filter-replace'));
                }else{
                    snippet.html($(data));
                }

                if(form.closest('.js-history-data').length===0) {
                    History.pushState(null, $(document).find("title").text(), $(data).find('form').data('url'));
                }
                // init form ...
                form.removeData('ajaxCall');

                snippet.csInit();
                snippet.find('.js-loading-indicator').hide();

                var event = jQuery.Event('csSnippet:postReplace');
                event.replacedTarget = snippet.find('.js-filter-replace, .js-result-replace');
                $(':root').trigger(event);

            }
        }));
        snippet.find('.js-loading-indicator').show();
        // var container=snippet.find('.js-result-replace');
    }

    $.csInit(function (initEvent) {
        initEvent.container.find('.csSnippet.js-filter-snippet').each(function () {
            initSearch($(this));
        });
        if (initEvent.container.hasClass('js-filter-replace') || initEvent.container.hasClass('js-result-replace')) {
            initSearch(initEvent.container);
        }

        if(initEvent.container.hasClass('csSnippet js-filter-snippet')){
            initSearch($(initEvent.container));
        }
    });
    var filterOption = $('.toolbar__opt .toolbar__block button').length;
    if (filterOption === 4) {
        $('.view__toolbar .toolbar__opt').addClass('filterMore');
    }

});
